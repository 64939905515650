<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Profile
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Mobile number
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Date redeemed
          </s-text>
        </v-col>
      </v-row>
      <div v-if="referrals.code_redeemed_by">
        <user-card
          v-for="user in referrals.code_redeemed_by"
          :key="user"
          :id="user"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import UserCard from '@/components/cards/UserCard'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'AppReferrals',
  components: {
    loader: Loader,
    'user-card': UserCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      referrals: 'user/currentUserReferrals',
    }),
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDate,
    getReferrals () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserReferrals', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  watch: {
    referrals: {
      handler () {
        if (this.referrals) return
        this.getReferrals()
      },
      immediate: true,
    },
  },
}
</script>
